
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document != 'undefined' ? document.currentScript?.src : undefined;
  if (typeof __filename != 'undefined') _scriptDir ||= __filename;
  return (
function(moduleArg = {}) {

function aa(){e.buffer!=l.buffer&&m();return l}function n(){e.buffer!=l.buffer&&m();return ba}function p(){e.buffer!=l.buffer&&m();return ca}function r(){e.buffer!=l.buffer&&m();return da}function ea(){e.buffer!=l.buffer&&m();return fa}
var v=moduleArg,ha,x,readyPromise=new Promise((a,b)=>{ha=a;x=b}),ia=Object.assign({},v),ja="./this.program",z=(a,b)=>{throw b;},ka="object"==typeof window,A="function"==typeof importScripts,B="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,D=v.ENVIRONMENT_IS_PTHREAD||!1,E="";function la(a){return v.locateFile?v.locateFile(a,E):E+a}var ma,G,H;
if(B){var fs=require("fs"),na=require("path");E=A?na.dirname(E)+"/":__dirname+"/";ma=(a,b)=>{a=I(a)?new URL(a):na.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};H=a=>{a=ma(a,!0);a.buffer||(a=new Uint8Array(a));return a};G=(a,b,c,d=!0)=>{a=I(a)?new URL(a):na.normalize(a);fs.readFile(a,d?void 0:"utf8",(h,g)=>{h?c(h):b(d?g.buffer:g)})};!v.thisProgram&&1<process.argv.length&&(ja=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);z=(a,b)=>{process.exitCode=a;throw b;};global.Worker=require("worker_threads").Worker}else if(ka||
A)A?E=self.location.href:"undefined"!=typeof document&&document.currentScript&&(E=document.currentScript.src),(typeof _scriptDir !== "undefined" && _scriptDir)&&(E=_scriptDir),E.startsWith("blob:")?E="":E=E.substr(0,E.replace(/[?#].*/,"").lastIndexOf("/")+1),B||(ma=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},A&&(H=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),G=(a,b,c)=>{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType=
"arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)});B&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var oa=console.log.bind(console),pa=console.error.bind(console);B&&(oa=(...a)=>fs.writeSync(1,a.join(" ")+"\n"),pa=(...a)=>fs.writeSync(2,a.join(" ")+"\n"));var qa=oa,J=pa;Object.assign(v,ia);ia=null;var e,ra,K=!1,L,l,ba,ca,da,fa;
function m(){var a=e.buffer;v.HEAP8=l=new Int8Array(a);v.HEAP16=new Int16Array(a);v.HEAPU8=ba=new Uint8Array(a);v.HEAPU16=new Uint16Array(a);v.HEAP32=ca=new Int32Array(a);v.HEAPU32=da=new Uint32Array(a);v.HEAPF32=new Float32Array(a);v.HEAPF64=fa=new Float64Array(a)}var sa=16777216;
if(D)e=v.wasmMemory;else if(v.wasmMemory)e=v.wasmMemory;else if(e=new WebAssembly.Memory({initial:sa/65536,maximum:65536,shared:!0}),!(e.buffer instanceof SharedArrayBuffer))throw J("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),B&&J("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and/or recent version)"),
Error("bad memory");m();sa=e.buffer.byteLength;var ta=[],ua=[],va=[],M=0,wa=null,N=null;function xa(){M--;if(0==M&&(null!==wa&&(clearInterval(wa),wa=null),N)){var a=N;N=null;a()}}function ya(a){a="Aborted("+a+")";J(a);K=!0;L=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");x(a);throw a;}var za=a=>a.startsWith("data:application/octet-stream;base64,"),I=a=>a.startsWith("file://"),O;O="ort-wasm-threaded.wasm";za(O)||(O=la(O));
function Aa(a){if(H)return H(a);throw"both async and sync fetching of the wasm failed";}function Ba(a){if(ka||A){if("function"==typeof fetch&&!I(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw`failed to load wasm binary file at '${a}'`;return b.arrayBuffer()}).catch(()=>Aa(a));if(G)return new Promise((b,c)=>{G(a,d=>b(new Uint8Array(d)),c)})}return Promise.resolve().then(()=>Aa(a))}
function Ca(a,b,c){return Ba(a).then(d=>WebAssembly.instantiate(d,b)).then(c,d=>{J(`failed to asynchronously prepare wasm: ${d}`);ya(d)})}function Da(a,b){var c=O;return"function"!=typeof WebAssembly.instantiateStreaming||za(c)||I(c)||B||"function"!=typeof fetch?Ca(c,a,b):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,a).then(b,function(h){J(`wasm streaming compile failed: ${h}`);J("falling back to ArrayBuffer instantiation");return Ca(c,a,b)}))}
var P,Ea={799444:(a,b,c,d)=>{if("undefined"==typeof v||!v.bb)return 1;a=Q(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=v.bb.get(a);if(!a)return 2;b>>>=0;c>>>=0;d>>>=0;if(b+c>a.byteLength)return 3;try{return n().set(a.subarray(b,b+c),d>>>0),0}catch{return 4}}};function R(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}
var Fa=a=>{a.terminate();a.onmessage=()=>{}},Ha=a=>{0==S.Oa.length&&(Ga(),S.Xa(S.Oa[0]));var b=S.Oa.pop();if(!b)return 6;S.Pa.push(b);S.La[a.Na]=b;b.Na=a.Na;var c={cmd:"run",start_routine:a.gb,arg:a.cb,pthread_ptr:a.Na};B&&b.unref();b.postMessage(c,a.mb);return 0},T=0,Ja=a=>{var b=Ia();a=a();U(b);return a},V=(a,b,...c)=>Ja(()=>{for(var d=c.length,h=Ka(8*d),g=h>>>3,k=0;k<c.length;k++){var t=c[k];ea()[g+k>>>0]=t}return La(a,0,d,h,b)});
function Ma(a){if(D)return V(0,1,a);L=a;0<T||(S.hb(),v.onExit?.(a),K=!0);z(a,new R(a))}var Oa=a=>{L=a;if(D)throw Na(a),"unwind";Ma(a)};function Pa(){for(var a=v.numThreads;a--;)Ga();ta.unshift(()=>{M++;Qa(()=>xa())})}function Ga(){var a=la("ort-wasm-threaded.worker.js");a=new Worker(a);S.Oa.push(a)}function Qa(a){D?a():Promise.all(S.Oa.map(S.Xa)).then(a)}
var S={Oa:[],Pa:[],ab:[],La:{},Va(){D?(S.receiveObjectTransfer=S.fb,S.threadInitTLS=S.$a,S.setExitStatus=S.Za):Pa()},Za:a=>L=a,pb:["$terminateWorker"],hb:()=>{for(var a of S.Pa)Fa(a);for(a of S.Oa)Fa(a);S.Oa=[];S.Pa=[];S.La=[]},Ya:a=>{var b=a.Na;delete S.La[b];S.Oa.push(a);S.Pa.splice(S.Pa.indexOf(a),1);a.Na=0;Ra(b)},fb(){},$a(){S.ab.forEach(a=>a())},Xa:a=>new Promise(b=>{a.onmessage=g=>{g=g.data;var k=g.cmd;if(g.targetThread&&g.targetThread!=W()){var t=S.La[g.targetThread];t?t.postMessage(g,g.transferList):
J(`Internal error! Worker sent a message "${k}" to target pthread ${g.targetThread}, but that thread no longer exists!`)}else if("checkMailbox"===k)Sa();else if("spawnThread"===k)Ha(g);else if("cleanupThread"===k)S.Ya(S.La[g.thread]);else if("killThread"===k)g=g.thread,k=S.La[g],delete S.La[g],Fa(k),Ra(g),S.Pa.splice(S.Pa.indexOf(k),1),k.Na=0;else if("cancelThread"===k)S.La[g.thread].postMessage({cmd:"cancel"});else if("loaded"===k)a.loaded=!0,B&&!a.Na&&a.unref(),b(a);else if("alert"===k)alert(`Thread ${g.threadId}: ${g.text}`);
else if("setimmediate"===g.target)a.postMessage(g);else if("callHandler"===k)v[g.handler](...g.args);else k&&J(`worker sent an unknown command ${k}`)};a.onerror=g=>{J(`${"worker sent an error!"} ${g.filename}:${g.lineno}: ${g.message}`);throw g;};B&&(a.on("message",g=>a.onmessage({data:g})),a.on("error",g=>a.onerror(g)));var c=[],d=["onExit"],h;for(h of d)v.hasOwnProperty(h)&&c.push(h);a.postMessage({cmd:"load",handlers:c,urlOrBlob:v.mainScriptUrlOrBlob||_scriptDir,wasmMemory:e,wasmModule:ra})})};
v.PThread=S;var Ta=a=>{for(;0<a.length;)a.shift()(v)};v.establishStackSpace=()=>{var a=W(),b=r()[a+52>>>2>>>0];a=r()[a+56>>>2>>>0];Ua(b,b-a);U(b)};function Na(a){if(D)return V(1,0,a);Oa(a)}var Va=[],Wa;v.invokeEntryPoint=(a,b)=>{T=0;var c=Va[a];c||(a>=Va.length&&(Va.length=a+1),Va[a]=c=Wa.get(a));a=c(b);0<T?S.Za(a):Xa(a)};class Ya{constructor(a){this.Ua=a-24}Va(a,b){r()[this.Ua+16>>>2>>>0]=0;r()[this.Ua+4>>>2>>>0]=a;r()[this.Ua+8>>>2>>>0]=b}}var Za=0,$a=0;
function ab(a,b,c,d){return D?V(2,1,a,b,c,d):bb(a,b,c,d)}function bb(a,b,c,d){a>>>=0;b>>>=0;c>>>=0;d>>>=0;if("undefined"==typeof SharedArrayBuffer)return J("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var h=[];if(D&&0===h.length)return ab(a,b,c,d);a={gb:c,Na:a,cb:d,mb:h};return D?(a.ob="spawnThread",postMessage(a,h),0):Ha(a)}
var cb="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,db=(a,b,c)=>{b>>>=0;var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.buffer&&cb)return cb.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,c):a.subarray(b,c));for(d="";b<c;){var h=a[b++];if(h&128){var g=a[b++]&63;if(192==(h&224))d+=String.fromCharCode((h&31)<<6|g);else{var k=a[b++]&63;h=224==(h&240)?(h&15)<<12|g<<6|k:(h&7)<<18|g<<12|k<<6|a[b++]&63;65536>h?d+=String.fromCharCode(h):(h-=65536,d+=String.fromCharCode(55296|h>>
10,56320|h&1023))}}else d+=String.fromCharCode(h)}return d},Q=(a,b)=>(a>>>=0)?db(n(),a,b):"";function eb(a,b,c){return D?V(3,1,a,b,c):0}function fb(a,b){if(D)return V(4,1,a,b)}
var gb=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);127>=d?b++:2047>=d?b+=2:55296<=d&&57343>=d?(b+=4,++c):b+=3}return b},hb=(a,b,c,d)=>{c>>>=0;if(!(0<d))return 0;var h=c;d=c+d-1;for(var g=0;g<a.length;++g){var k=a.charCodeAt(g);if(55296<=k&&57343>=k){var t=a.charCodeAt(++g);k=65536+((k&1023)<<10)|t&1023}if(127>=k){if(c>=d)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=d)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=d)break;b[c++>>>0]=224|k>>12}else{if(c+3>=d)break;b[c++>>>0]=240|k>>
18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-h},X=(a,b,c)=>hb(a,n(),b,c);function ib(a,b){if(D)return V(5,1,a,b)}function jb(a,b,c){if(D)return V(6,1,a,b,c)}function kb(a,b,c){return D?V(7,1,a,b,c):0}function lb(a,b){if(D)return V(8,1,a,b)}function mb(a,b,c){if(D)return V(9,1,a,b,c)}function nb(a,b,c,d){if(D)return V(10,1,a,b,c,d)}function ob(a,b,c,d){if(D)return V(11,1,a,b,c,d)}function pb(a,b,c,d){if(D)return V(12,1,a,b,c,d)}
function qb(a){if(D)return V(13,1,a)}function rb(a,b){if(D)return V(14,1,a,b)}function sb(a,b,c){if(D)return V(15,1,a,b,c)}function tb(a){a>>>=0;"function"===typeof Atomics.nb&&(Atomics.nb(p(),a>>>2,a).value.then(Sa),a+=128,Atomics.store(p(),a>>>2,1))}v.__emscripten_thread_mailbox_await=tb;var Sa=()=>{var a=W();if(a&&(tb(a),a=ub,!K))try{if(a(),!(0<T))try{D?Xa(L):Oa(L)}catch(b){b instanceof R||"unwind"==b||z(1,b)}}catch(b){b instanceof R||"unwind"==b||z(1,b)}};v.checkMailbox=Sa;
var vb=[],Y=a=>0===a%4&&(0!==a%100||0===a%400),wb=[0,31,60,91,121,152,182,213,244,274,305,335],xb=[0,31,59,90,120,151,181,212,243,273,304,334];function yb(a,b,c,d,h,g,k,t){return D?V(16,1,a,b,c,d,h,g,k,t):-52}function zb(a,b,c,d,h,g,k){if(D)return V(17,1,a,b,c,d,h,g,k)}
var Ab=[],Bb={},Db=()=>{if(!Cb){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ja||"./this.program"},b;for(b in Bb)void 0===Bb[b]?delete a[b]:a[b]=Bb[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);Cb=c}return Cb},Cb;
function Eb(a,b){if(D)return V(18,1,a,b);a>>>=0;b>>>=0;var c=0;Db().forEach((d,h)=>{var g=b+c;h=r()[a+4*h>>>2>>>0]=g;for(g=0;g<d.length;++g)aa()[h++>>>0]=d.charCodeAt(g);aa()[h>>>0]=0;c+=d.length+1});return 0}function Ib(a,b){if(D)return V(19,1,a,b);a>>>=0;b>>>=0;var c=Db();r()[a>>>2>>>0]=c.length;var d=0;c.forEach(h=>d+=h.length+1);r()[b>>>2>>>0]=d;return 0}function Jb(a){return D?V(20,1,a):52}function Kb(a,b,c,d){return D?V(21,1,a,b,c,d):52}function Lb(a,b,c,d,h){return D?V(22,1,a,b,c,d,h):70}
var Mb=[null,[],[]];function Nb(a,b,c,d){if(D)return V(23,1,a,b,c,d);b>>>=0;c>>>=0;d>>>=0;for(var h=0,g=0;g<c;g++){var k=r()[b>>>2>>>0],t=r()[b+4>>>2>>>0];b+=8;for(var C=0;C<t;C++){var w=n()[k+C>>>0],y=Mb[a];0===w||10===w?((1===a?qa:J)(db(y,0)),y.length=0):y.push(w)}h+=t}r()[d>>>2>>>0]=h;return 0}var Ob=[31,29,31,30,31,30,31,31,30,31,30,31],Pb=[31,28,31,30,31,30,31,31,30,31,30,31];function Qb(a){var b=Array(gb(a)+1);hb(a,b,0,b.length);return b}var Rb=(a,b)=>{aa().set(a,b>>>0)};
function Sb(a,b,c,d){function h(f,q,u){for(f="number"==typeof f?f.toString():f||"";f.length<q;)f=u[0]+f;return f}function g(f,q){return h(f,q,"0")}function k(f,q){function u(Fb){return 0>Fb?-1:0<Fb?1:0}var F;0===(F=u(f.getFullYear()-q.getFullYear()))&&0===(F=u(f.getMonth()-q.getMonth()))&&(F=u(f.getDate()-q.getDate()));return F}function t(f){switch(f.getDay()){case 0:return new Date(f.getFullYear()-1,11,29);case 1:return f;case 2:return new Date(f.getFullYear(),0,3);case 3:return new Date(f.getFullYear(),
0,2);case 4:return new Date(f.getFullYear(),0,1);case 5:return new Date(f.getFullYear()-1,11,31);case 6:return new Date(f.getFullYear()-1,11,30)}}function C(f){var q=f.Qa;for(f=new Date((new Date(f.Ra+1900,0,1)).getTime());0<q;){var u=f.getMonth(),F=(Y(f.getFullYear())?Ob:Pb)[u];if(q>F-f.getDate())q-=F-f.getDate()+1,f.setDate(1),11>u?f.setMonth(u+1):(f.setMonth(0),f.setFullYear(f.getFullYear()+1));else{f.setDate(f.getDate()+q);break}}u=new Date(f.getFullYear()+1,0,4);q=t(new Date(f.getFullYear(),
0,4));u=t(u);return 0>=k(q,f)?0>=k(u,f)?f.getFullYear()+1:f.getFullYear():f.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;d>>>=0;var w=r()[d+40>>>2>>>0];d={kb:p()[d>>>2>>>0],jb:p()[d+4>>>2>>>0],Sa:p()[d+8>>>2>>>0],Wa:p()[d+12>>>2>>>0],Ta:p()[d+16>>>2>>>0],Ra:p()[d+20>>>2>>>0],Ma:p()[d+24>>>2>>>0],Qa:p()[d+28>>>2>>>0],qb:p()[d+32>>>2>>>0],ib:p()[d+36>>>2>>>0],lb:w?Q(w):""};c=Q(c);w={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y",
"%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var y in w)c=c.replace(new RegExp(y,"g"),w[y]);var Gb="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),Hb="January February March April May June July August September October November December".split(" ");w={"%a":f=>Gb[f.Ma].substring(0,3),
"%A":f=>Gb[f.Ma],"%b":f=>Hb[f.Ta].substring(0,3),"%B":f=>Hb[f.Ta],"%C":f=>g((f.Ra+1900)/100|0,2),"%d":f=>g(f.Wa,2),"%e":f=>h(f.Wa,2," "),"%g":f=>C(f).toString().substring(2),"%G":C,"%H":f=>g(f.Sa,2),"%I":f=>{f=f.Sa;0==f?f=12:12<f&&(f-=12);return g(f,2)},"%j":f=>{for(var q=0,u=0;u<=f.Ta-1;q+=(Y(f.Ra+1900)?Ob:Pb)[u++]);return g(f.Wa+q,3)},"%m":f=>g(f.Ta+1,2),"%M":f=>g(f.jb,2),"%n":()=>"\n","%p":f=>0<=f.Sa&&12>f.Sa?"AM":"PM","%S":f=>g(f.kb,2),"%t":()=>"\t","%u":f=>f.Ma||7,"%U":f=>g(Math.floor((f.Qa+
7-f.Ma)/7),2),"%V":f=>{var q=Math.floor((f.Qa+7-(f.Ma+6)%7)/7);2>=(f.Ma+371-f.Qa-2)%7&&q++;if(q)53==q&&(u=(f.Ma+371-f.Qa)%7,4==u||3==u&&Y(f.Ra)||(q=1));else{q=52;var u=(f.Ma+7-f.Qa-1)%7;(4==u||5==u&&Y(f.Ra%400-1))&&q++}return g(q,2)},"%w":f=>f.Ma,"%W":f=>g(Math.floor((f.Qa+7-(f.Ma+6)%7)/7),2),"%y":f=>(f.Ra+1900).toString().substring(2),"%Y":f=>f.Ra+1900,"%z":f=>{f=f.ib;var q=0<=f;f=Math.abs(f)/60;return(q?"+":"-")+String("0000"+(f/60*100+f%60)).slice(-4)},"%Z":f=>f.lb,"%%":()=>"%"};c=c.replace(/%%/g,
"\x00\x00");for(y in w)c.includes(y)&&(c=c.replace(new RegExp(y,"g"),w[y](d)));c=c.replace(/\0\0/g,"%");y=Qb(c);if(y.length>b)return 0;Rb(y,a);return y.length-1}S.Va();
var Tb=[Ma,Na,ab,eb,fb,ib,jb,kb,lb,mb,nb,ob,pb,qb,rb,sb,yb,zb,Eb,Ib,Jb,Kb,Lb,Nb],Wb={b:function(a,b,c){a>>>=0;(new Ya(a)).Va(b>>>0,c>>>0);Za=a;$a++;throw Za;},L:function(a){Ub(a>>>0,!A,1,!ka,131072,!1);S.$a()},j:function(a){a>>>=0;D?postMessage({cmd:"cleanupThread",thread:a}):S.Ya(S.La[a])},H:bb,h:eb,S:fb,D:ib,F:jb,T:kb,Q:lb,J:mb,P:nb,n:ob,E:pb,B:qb,R:rb,C:sb,p:()=>1,z:function(a,b){a>>>=0;a==b>>>0?setTimeout(Sa):D?postMessage({targetThread:a,cmd:"checkMailbox"}):(a=S.La[a])&&a.postMessage({cmd:"checkMailbox"})},
I:function(a,b,c,d,h){b>>>=0;c>>>=0;vb.length=d;h=h>>>0>>>3;for(var g=0;g<d;g++)vb[g]=ea()[h+g>>>0];a=b?Ea[b]:Tb[a];S.eb=c;c=a(...vb);S.eb=0;return c},K:tb,o:function(a){B&&S.La[a>>>0].ref()},s:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);p()[c>>>2>>>0]=a.getUTCSeconds();p()[c+4>>>2>>>0]=a.getUTCMinutes();p()[c+8>>>2>>>0]=a.getUTCHours();p()[c+12>>>2>>>0]=a.getUTCDate();p()[c+16>>>2>>>0]=a.getUTCMonth();p()[c+20>>>2>>>0]=a.getUTCFullYear()-1900;p()[c+
24>>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;p()[c+28>>>2>>>0]=a},t:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);p()[c>>>2>>>0]=a.getSeconds();p()[c+4>>>2>>>0]=a.getMinutes();p()[c+8>>>2>>>0]=a.getHours();p()[c+12>>>2>>>0]=a.getDate();p()[c+16>>>2>>>0]=a.getMonth();p()[c+20>>>2>>>0]=a.getFullYear()-1900;p()[c+24>>>2>>>0]=a.getDay();b=(Y(a.getFullYear())?wb:xb)[a.getMonth()]+a.getDate()-1|0;p()[c+28>>>2>>>
0]=b;p()[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var d=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();a=(b!=d&&a.getTimezoneOffset()==Math.min(d,b))|0;p()[c+32>>>2>>>0]=a},u:function(a){a>>>=0;var b=new Date(p()[a+20>>>2>>>0]+1900,p()[a+16>>>2>>>0],p()[a+12>>>2>>>0],p()[a+8>>>2>>>0],p()[a+4>>>2>>>0],p()[a>>>2>>>0],0),c=p()[a+32>>>2>>>0],d=b.getTimezoneOffset(),h=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),g=(new Date(b.getFullYear(),
0,1)).getTimezoneOffset(),k=Math.min(g,h);0>c?p()[a+32>>>2>>>0]=Number(h!=g&&k==d):0<c!=(k==d)&&(h=Math.max(g,h),b.setTime(b.getTime()+6E4*((0<c?k:h)-d)));p()[a+24>>>2>>>0]=b.getDay();c=(Y(b.getFullYear())?wb:xb)[b.getMonth()]+b.getDate()-1|0;p()[a+28>>>2>>>0]=c;p()[a>>>2>>>0]=b.getSeconds();p()[a+4>>>2>>>0]=b.getMinutes();p()[a+8>>>2>>>0]=b.getHours();p()[a+12>>>2>>>0]=b.getDate();p()[a+16>>>2>>>0]=b.getMonth();p()[a+20>>>2>>>0]=b.getYear();a=b.getTime();a=isNaN(a)?-1:a/1E3;Vb((P=a,1<=+Math.abs(P)?
0<P?+Math.floor(P/4294967296)>>>0:~~+Math.ceil((P-+(~~P>>>0))/4294967296)>>>0:0));return a>>>0},q:yb,r:zb,y:function(a,b,c,d){a>>>=0;b>>>=0;c>>>=0;d>>>=0;var h=(new Date).getFullYear(),g=new Date(h,0,1),k=new Date(h,6,1);h=g.getTimezoneOffset();var t=k.getTimezoneOffset(),C=Math.max(h,t);r()[a>>>2>>>0]=60*C;p()[b>>>2>>>0]=Number(h!=t);a=w=>w.toLocaleTimeString(void 0,{hour12:!1,timeZoneName:"short"}).split(" ")[1];g=a(g);k=a(k);t<h?(X(g,c,17),X(k,d,17)):(X(g,d,17),X(k,c,17))},c:()=>{ya("")},O:function(a,
b,c){a>>>=0;b>>>=0;c>>>=0;Ab.length=0;for(var d;d=n()[b++>>>0];){var h=105!=d;h&=112!=d;c+=h&&c%8?4:0;Ab.push(112==d?r()[c>>>2>>>0]:105==d?p()[c>>>2>>>0]:ea()[c>>>3>>>0]);c+=h?8:4}return Ea[a](...Ab)},k:()=>{},i:()=>Date.now(),U:()=>{T+=1;throw"unwind";},A:function(){return 4294901760},e:()=>performance.timeOrigin+performance.now(),f:()=>B?require("os").cpus().length:navigator.hardwareConcurrency,x:function(a){a>>>=0;var b=n().length;if(a<=b||4294901760<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+
.2/c);d=Math.min(d,a+100663296);var h=Math;d=Math.max(a,d);a:{h=(h.min.call(h,4294901760,d+(65536-d%65536)%65536)-e.buffer.byteLength+65535)/65536;try{e.grow(h);m();var g=1;break a}catch(k){}g=void 0}if(g)return!0}return!1},M:Eb,N:Ib,G:Oa,g:Jb,m:Kb,v:Lb,l:Nb,a:e||v.wasmMemory,w:Sb,d:function(a,b,c,d){return Sb(a>>>0,b>>>0,c>>>0,d>>>0)}},Z=function(){function a(c,d){Z=c.exports;Z=Xb();S.ab.push(Z.ya);Wa=Z.za;ua.unshift(Z.V);ra=d;xa();return Z}var b={a:Wb};M++;if(v.instantiateWasm)try{return v.instantiateWasm(b,
a)}catch(c){J(`Module.instantiateWasm callback failed with error: ${c}`),x(c)}Da(b,function(c){a(c.instance,c.module)}).catch(x);return{}}();v._OrtInit=(a,b)=>(v._OrtInit=Z.W)(a,b);v._OrtGetLastError=(a,b)=>(v._OrtGetLastError=Z.X)(a,b);v._OrtCreateSessionOptions=(a,b,c,d,h,g,k,t,C,w)=>(v._OrtCreateSessionOptions=Z.Y)(a,b,c,d,h,g,k,t,C,w);v._OrtAppendExecutionProvider=(a,b)=>(v._OrtAppendExecutionProvider=Z.Z)(a,b);v._OrtAddFreeDimensionOverride=(a,b,c)=>(v._OrtAddFreeDimensionOverride=Z._)(a,b,c);
v._OrtAddSessionConfigEntry=(a,b,c)=>(v._OrtAddSessionConfigEntry=Z.$)(a,b,c);v._OrtReleaseSessionOptions=a=>(v._OrtReleaseSessionOptions=Z.aa)(a);v._OrtCreateSession=(a,b,c)=>(v._OrtCreateSession=Z.ba)(a,b,c);v._OrtReleaseSession=a=>(v._OrtReleaseSession=Z.ca)(a);v._OrtGetInputOutputCount=(a,b,c)=>(v._OrtGetInputOutputCount=Z.da)(a,b,c);v._OrtGetInputName=(a,b)=>(v._OrtGetInputName=Z.ea)(a,b);v._OrtGetOutputName=(a,b)=>(v._OrtGetOutputName=Z.fa)(a,b);v._OrtFree=a=>(v._OrtFree=Z.ga)(a);
v._OrtCreateTensor=(a,b,c,d,h,g)=>(v._OrtCreateTensor=Z.ha)(a,b,c,d,h,g);v._OrtGetTensorData=(a,b,c,d,h)=>(v._OrtGetTensorData=Z.ia)(a,b,c,d,h);v._OrtReleaseTensor=a=>(v._OrtReleaseTensor=Z.ja)(a);v._OrtCreateRunOptions=(a,b,c,d)=>(v._OrtCreateRunOptions=Z.ka)(a,b,c,d);v._OrtAddRunConfigEntry=(a,b,c)=>(v._OrtAddRunConfigEntry=Z.la)(a,b,c);v._OrtReleaseRunOptions=a=>(v._OrtReleaseRunOptions=Z.ma)(a);v._OrtCreateBinding=a=>(v._OrtCreateBinding=Z.na)(a);
v._OrtBindInput=(a,b,c)=>(v._OrtBindInput=Z.oa)(a,b,c);v._OrtBindOutput=(a,b,c,d)=>(v._OrtBindOutput=Z.pa)(a,b,c,d);v._OrtClearBoundOutputs=a=>(v._OrtClearBoundOutputs=Z.qa)(a);v._OrtReleaseBinding=a=>(v._OrtReleaseBinding=Z.ra)(a);v._OrtRunWithBinding=(a,b,c,d,h)=>(v._OrtRunWithBinding=Z.sa)(a,b,c,d,h);v._OrtRun=(a,b,c,d,h,g,k,t)=>(v._OrtRun=Z.ta)(a,b,c,d,h,g,k,t);v._OrtEndProfiling=a=>(v._OrtEndProfiling=Z.ua)(a);var W=v._pthread_self=()=>(W=v._pthread_self=Z.va)();v._malloc=a=>(v._malloc=Z.wa)(a);
v._free=a=>(v._free=Z.xa)(a);v.__emscripten_tls_init=()=>(v.__emscripten_tls_init=Z.ya)();var Ub=v.__emscripten_thread_init=(a,b,c,d,h,g)=>(Ub=v.__emscripten_thread_init=Z.Aa)(a,b,c,d,h,g);v.__emscripten_thread_crashed=()=>(v.__emscripten_thread_crashed=Z.Ba)();
var La=(a,b,c,d,h)=>(La=Z.Ca)(a,b,c,d,h),Ra=a=>(Ra=Z.Da)(a),Xa=v.__emscripten_thread_exit=a=>(Xa=v.__emscripten_thread_exit=Z.Ea)(a),ub=()=>(ub=Z.Fa)(),Vb=a=>(Vb=Z.Ga)(a),Ua=(a,b)=>(Ua=Z.Ha)(a,b),U=a=>(U=Z.Ia)(a),Ka=a=>(Ka=Z.Ja)(a),Ia=()=>(Ia=Z.Ka)();function Xb(){var a=Z;a=Object.assign({},a);var b=d=>()=>d()>>>0,c=d=>h=>d(h)>>>0;a.va=b(a.va);a.wa=c(a.wa);a.emscripten_main_runtime_thread_id=b(a.emscripten_main_runtime_thread_id);a.Ja=c(a.Ja);a.Ka=b(a.Ka);return a}v.wasmMemory=e;v.stackSave=()=>Ia();
v.stackRestore=a=>U(a);v.stackAlloc=a=>Ka(a);v.keepRuntimeAlive=()=>0<T;v.UTF8ToString=Q;v.stringToUTF8=X;v.lengthBytesUTF8=gb;v.ExitStatus=R;v.PThread=S;var Yb;N=function Zb(){Yb||$b();Yb||(N=Zb)};function $b(){if(!(0<M))if(D)ha(v),D||Ta(ua),startWorker(v);else{if(v.preRun)for("function"==typeof v.preRun&&(v.preRun=[v.preRun]);v.preRun.length;)ta.unshift(v.preRun.shift());Ta(ta);0<M||Yb||(Yb=!0,v.calledRun=!0,K||(D||Ta(ua),ha(v),D||Ta(va)))}}$b();


  return readyPromise
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasmThreaded);
