
var ortWasm = (() => {
  var _scriptDir = typeof document != 'undefined' ? document.currentScript?.src : undefined;
  if (typeof __filename != 'undefined') _scriptDir ||= __filename;
  return (
function(moduleArg = {}) {

var f=moduleArg,k,l,readyPromise=new Promise((a,b)=>{k=a;l=b}),u=Object.assign({},f),v="./this.program",aa="object"==typeof window,w="function"==typeof importScripts,ba="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,z="",A,B,C;
if(ba){var fs=require("fs"),D=require("path");z=w?D.dirname(z)+"/":__dirname+"/";A=(a,b)=>{a=E(a)?new URL(a):D.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};C=a=>{a=A(a,!0);a.buffer||(a=new Uint8Array(a));return a};B=(a,b,c,e=!0)=>{a=E(a)?new URL(a):D.normalize(a);fs.readFile(a,e?void 0:"utf8",(g,h)=>{g?c(g):b(e?h.buffer:h)})};!f.thisProgram&&1<process.argv.length&&(v=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2)}else if(aa||w)w?z=self.location.href:"undefined"!=typeof document&&
document.currentScript&&(z=document.currentScript.src),_scriptDir&&(z=_scriptDir),z.startsWith("blob:")?z="":z=z.substr(0,z.replace(/[?#].*/,"").lastIndexOf("/")+1),A=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},w&&(C=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),B=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";e.onload=()=>{200==e.status||0==e.status&&
e.response?b(e.response):c()};e.onerror=c;e.send(null)};var ca=console.log.bind(console),F=console.error.bind(console);Object.assign(f,u);u=null;var G,da=!1,H,I,J,K,ea;function fa(){var a=G.buffer;f.HEAP8=H=new Int8Array(a);f.HEAP16=new Int16Array(a);f.HEAPU8=I=new Uint8Array(a);f.HEAPU16=new Uint16Array(a);f.HEAP32=J=new Int32Array(a);f.HEAPU32=K=new Uint32Array(a);f.HEAPF32=new Float32Array(a);f.HEAPF64=ea=new Float64Array(a)}var L=[],M=[],ha=[],N=0,O=null,P=null;
function ia(a){a="Aborted("+a+")";F(a);da=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");l(a);throw a;}var ja=a=>a.startsWith("data:application/octet-stream;base64,"),E=a=>a.startsWith("file://"),Q;Q="ort-wasm.wasm";if(!ja(Q)){var ka=Q;Q=f.locateFile?f.locateFile(ka,z):z+ka}function la(a){if(C)return C(a);throw"both async and sync fetching of the wasm failed";}
function ma(a){if(aa||w){if("function"==typeof fetch&&!E(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw`failed to load wasm binary file at '${a}'`;return b.arrayBuffer()}).catch(()=>la(a));if(B)return new Promise((b,c)=>{B(a,e=>b(new Uint8Array(e)),c)})}return Promise.resolve().then(()=>la(a))}function na(a,b,c){return ma(a).then(e=>WebAssembly.instantiate(e,b)).then(c,e=>{F(`failed to asynchronously prepare wasm: ${e}`);ia(e)})}
function oa(a,b){var c=Q;return"function"!=typeof WebAssembly.instantiateStreaming||ja(c)||E(c)||ba||"function"!=typeof fetch?na(c,a,b):fetch(c,{credentials:"same-origin"}).then(e=>WebAssembly.instantiateStreaming(e,a).then(b,function(g){F(`wasm streaming compile failed: ${g}`);F("falling back to ArrayBuffer instantiation");return na(c,a,b)}))}
var R,pa={798088:(a,b,c,e)=>{if("undefined"==typeof f||!f.ya)return 1;a=S(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=f.ya.get(a);if(!a)return 2;b>>>=0;c>>>=0;if(b+c>a.byteLength)return 3;try{return I.set(a.subarray(b,b+c),e>>>0>>>0),0}catch{return 4}}};class qa{constructor(a){this.wa=a-24}}
var ra=0,sa=0,ta="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,ua=(a,b,c)=>{b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&ta)return ta.decode(a.subarray(b,c));for(e="";b<c;){var g=a[b++];if(g&128){var h=a[b++]&63;if(192==(g&224))e+=String.fromCharCode((g&31)<<6|h);else{var m=a[b++]&63;g=224==(g&240)?(g&15)<<12|h<<6|m:(g&7)<<18|h<<12|m<<6|a[b++]&63;65536>g?e+=String.fromCharCode(g):(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else e+=String.fromCharCode(g)}return e},
S=(a,b)=>(a>>>=0)?ua(I,a,b):"",va=a=>{for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b},T=(a,b,c,e)=>{c>>>=0;if(!(0<e))return 0;var g=c;e=c+e-1;for(var h=0;h<a.length;++h){var m=a.charCodeAt(h);if(55296<=m&&57343>=m){var q=a.charCodeAt(++h);m=65536+((m&1023)<<10)|q&1023}if(127>=m){if(c>=e)break;b[c++>>>0]=m}else{if(2047>=m){if(c+1>=e)break;b[c++>>>0]=192|m>>6}else{if(65535>=m){if(c+2>=e)break;b[c++>>>0]=224|m>>12}else{if(c+3>=
e)break;b[c++>>>0]=240|m>>18;b[c++>>>0]=128|m>>12&63}b[c++>>>0]=128|m>>6&63}b[c++>>>0]=128|m&63}}b[c>>>0]=0;return c-g},U=a=>0===a%4&&(0!==a%100||0===a%400),za=[0,31,60,91,121,152,182,213,244,274,305,335],Aa=[0,31,59,90,120,151,181,212,243,273,304,334],V=[],W={},Ba=()=>{if(!X){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:v||"./this.program"},b;for(b in W)void 0===
W[b]?delete a[b]:a[b]=W[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);X=c}return X},X,Ca=[null,[],[]],Da=[31,29,31,30,31,30,31,31,30,31,30,31],Ea=[31,28,31,30,31,30,31,31,30,31,30,31];function Fa(a){var b=Array(va(a)+1);T(a,b,0,b.length);return b}
function Ga(a,b,c,e){function g(d,n,p){for(d="number"==typeof d?d.toString():d||"";d.length<n;)d=p[0]+d;return d}function h(d,n){return g(d,n,"0")}function m(d,n){function p(wa){return 0>wa?-1:0<wa?1:0}var y;0===(y=p(d.getFullYear()-n.getFullYear()))&&0===(y=p(d.getMonth()-n.getMonth()))&&(y=p(d.getDate()-n.getDate()));return y}function q(d){switch(d.getDay()){case 0:return new Date(d.getFullYear()-1,11,29);case 1:return d;case 2:return new Date(d.getFullYear(),0,3);case 3:return new Date(d.getFullYear(),
0,2);case 4:return new Date(d.getFullYear(),0,1);case 5:return new Date(d.getFullYear()-1,11,31);case 6:return new Date(d.getFullYear()-1,11,30)}}function x(d){var n=d.sa;for(d=new Date((new Date(d.ta+1900,0,1)).getTime());0<n;){var p=d.getMonth(),y=(U(d.getFullYear())?Da:Ea)[p];if(n>y-d.getDate())n-=y-d.getDate()+1,d.setDate(1),11>p?d.setMonth(p+1):(d.setMonth(0),d.setFullYear(d.getFullYear()+1));else{d.setDate(d.getDate()+n);break}}p=new Date(d.getFullYear()+1,0,4);n=q(new Date(d.getFullYear(),
0,4));p=q(p);return 0>=m(n,d)?0>=m(p,d)?d.getFullYear()+1:d.getFullYear():d.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;e>>>=0;var r=K[e+40>>>2>>>0];e={Ba:J[e>>>2>>>0],Aa:J[e+4>>>2>>>0],ua:J[e+8>>>2>>>0],xa:J[e+12>>>2>>>0],va:J[e+16>>>2>>>0],ta:J[e+20>>>2>>>0],na:J[e+24>>>2>>>0],sa:J[e+28>>>2>>>0],Da:J[e+32>>>2>>>0],za:J[e+36>>>2>>>0],Ca:r?S(r):""};c=S(c);r={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S",
"%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var t in r)c=c.replace(new RegExp(t,"g"),r[t]);var xa="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),ya="January February March April May June July August September October November December".split(" ");r={"%a":d=>xa[d.na].substring(0,3),"%A":d=>xa[d.na],
"%b":d=>ya[d.va].substring(0,3),"%B":d=>ya[d.va],"%C":d=>h((d.ta+1900)/100|0,2),"%d":d=>h(d.xa,2),"%e":d=>g(d.xa,2," "),"%g":d=>x(d).toString().substring(2),"%G":x,"%H":d=>h(d.ua,2),"%I":d=>{d=d.ua;0==d?d=12:12<d&&(d-=12);return h(d,2)},"%j":d=>{for(var n=0,p=0;p<=d.va-1;n+=(U(d.ta+1900)?Da:Ea)[p++]);return h(d.xa+n,3)},"%m":d=>h(d.va+1,2),"%M":d=>h(d.Aa,2),"%n":()=>"\n","%p":d=>0<=d.ua&&12>d.ua?"AM":"PM","%S":d=>h(d.Ba,2),"%t":()=>"\t","%u":d=>d.na||7,"%U":d=>h(Math.floor((d.sa+7-d.na)/7),2),"%V":d=>
{var n=Math.floor((d.sa+7-(d.na+6)%7)/7);2>=(d.na+371-d.sa-2)%7&&n++;if(n)53==n&&(p=(d.na+371-d.sa)%7,4==p||3==p&&U(d.ta)||(n=1));else{n=52;var p=(d.na+7-d.sa-1)%7;(4==p||5==p&&U(d.ta%400-1))&&n++}return h(n,2)},"%w":d=>d.na,"%W":d=>h(Math.floor((d.sa+7-(d.na+6)%7)/7),2),"%y":d=>(d.ta+1900).toString().substring(2),"%Y":d=>d.ta+1900,"%z":d=>{d=d.za;var n=0<=d;d=Math.abs(d)/60;return(n?"+":"-")+String("0000"+(d/60*100+d%60)).slice(-4)},"%Z":d=>d.Ca,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");for(t in r)c.includes(t)&&
(c=c.replace(new RegExp(t,"g"),r[t](e)));c=c.replace(/\0\0/g,"%");t=Fa(c);if(t.length>b)return 0;H.set(t,a>>>0);return t.length-1}
var Ia={a:function(a,b,c){a>>>=0;var e=new qa(a);K[e.wa+16>>>2>>>0]=0;K[e.wa+4>>>2>>>0]=b>>>0;K[e.wa+8>>>2>>>0]=c>>>0;ra=a;sa++;throw ra;},e:function(){return 0},H:function(){},x:function(){},z:function(){},J:function(){return 0},F:function(){},A:function(){},E:function(){},g:function(){},y:function(){},v:function(){},G:function(){},w:function(){},k:()=>1,I:function(a,b,c){b>>>=0;return I.copyWithin(a>>>0>>>0,b>>>0,b+(c>>>0)>>>0)},n:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:
NaN;c>>>=0;a=new Date(1E3*a);J[c>>>2>>>0]=a.getUTCSeconds();J[c+4>>>2>>>0]=a.getUTCMinutes();J[c+8>>>2>>>0]=a.getUTCHours();J[c+12>>>2>>>0]=a.getUTCDate();J[c+16>>>2>>>0]=a.getUTCMonth();J[c+20>>>2>>>0]=a.getUTCFullYear()-1900;J[c+24>>>2>>>0]=a.getUTCDay();J[c+28>>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},o:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);J[c>>>2>>>0]=a.getSeconds();J[c+4>>>2>>>0]=a.getMinutes();J[c+8>>>2>>>
0]=a.getHours();J[c+12>>>2>>>0]=a.getDate();J[c+16>>>2>>>0]=a.getMonth();J[c+20>>>2>>>0]=a.getFullYear()-1900;J[c+24>>>2>>>0]=a.getDay();J[c+28>>>2>>>0]=(U(a.getFullYear())?za:Aa)[a.getMonth()]+a.getDate()-1|0;J[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var e=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();J[c+32>>>2>>>0]=(b!=e&&a.getTimezoneOffset()==Math.min(e,b))|0},p:function(a){a>>>=0;var b=new Date(J[a+20>>>2>>>0]+1900,J[a+16>>>2>>>0],
J[a+12>>>2>>>0],J[a+8>>>2>>>0],J[a+4>>>2>>>0],J[a>>>2>>>0],0),c=J[a+32>>>2>>>0],e=b.getTimezoneOffset(),g=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),h=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),m=Math.min(h,g);0>c?J[a+32>>>2>>>0]=Number(g!=h&&m==e):0<c!=(m==e)&&(g=Math.max(h,g),b.setTime(b.getTime()+6E4*((0<c?m:g)-e)));J[a+24>>>2>>>0]=b.getDay();J[a+28>>>2>>>0]=(U(b.getFullYear())?za:Aa)[b.getMonth()]+b.getDate()-1|0;J[a>>>2>>>0]=b.getSeconds();J[a+4>>>2>>>0]=b.getMinutes();J[a+
8>>>2>>>0]=b.getHours();J[a+12>>>2>>>0]=b.getDate();J[a+16>>>2>>>0]=b.getMonth();J[a+20>>>2>>>0]=b.getYear();a=b.getTime();a=isNaN(a)?-1:a/1E3;Ha((R=a,1<=+Math.abs(R)?0<R?+Math.floor(R/4294967296)>>>0:~~+Math.ceil((R-+(~~R>>>0))/4294967296)>>>0:0));return a>>>0},l:function(){return-52},m:function(){},t:function(a,b,c,e){c>>>=0;e>>>=0;var g=(new Date).getFullYear(),h=new Date(g,0,1),m=new Date(g,6,1);g=h.getTimezoneOffset();var q=m.getTimezoneOffset();K[a>>>0>>>2>>>0]=60*Math.max(g,q);J[b>>>0>>>2>>>
0]=Number(g!=q);a=x=>x.toLocaleTimeString(void 0,{hour12:!1,timeZoneName:"short"}).split(" ")[1];h=a(h);m=a(m);q<g?(T(h,I,c,17),T(m,I,e,17)):(T(h,I,e,17),T(m,I,c,17))},d:()=>{ia("")},B:function(a,b,c){a>>>=0;b>>>=0;c>>>=0;V.length=0;for(var e;e=I[b++>>>0];){var g=105!=e;g&=112!=e;c+=g&&c%8?4:0;V.push(112==e?K[c>>>2>>>0]:105==e?J[c>>>2>>>0]:ea[c>>>3>>>0]);c+=g?8:4}return pa[a](...V)},h:()=>Date.now(),u:function(){return 4294901760},b:()=>performance.now(),s:function(a){a>>>=0;var b=I.length;if(4294901760<
a)return!1;for(var c=1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var g=Math;e=Math.max(a,e);a:{g=(g.min.call(g,4294901760,e+(65536-e%65536)%65536)-G.buffer.byteLength+65535)/65536;try{G.grow(g);fa();var h=1;break a}catch(m){}h=void 0}if(h)return!0}return!1},C:function(a,b){a>>>=0;b>>>=0;var c=0;Ba().forEach((e,g)=>{var h=b+c;g=K[a+4*g>>>2>>>0]=h;for(h=0;h<e.length;++h)H[g++>>>0]=e.charCodeAt(h);H[g>>>0]=0;c+=e.length+1});return 0},D:function(a,b){a>>>=0;b>>>=0;var c=Ba();K[a>>>2>>>0]=
c.length;var e=0;c.forEach(g=>e+=g.length+1);K[b>>>2>>>0]=e;return 0},f:()=>52,j:function(){return 52},q:function(){return 70},i:function(a,b,c,e){b>>>=0;c>>>=0;e>>>=0;for(var g=0,h=0;h<c;h++){var m=K[b>>>2>>>0],q=K[b+4>>>2>>>0];b+=8;for(var x=0;x<q;x++){var r=I[m+x>>>0],t=Ca[a];0===r||10===r?((1===a?ca:F)(ua(t,0)),t.length=0):t.push(r)}g+=q}K[e>>>2>>>0]=g;return 0},r:Ga,c:function(a,b,c,e){return Ga(a>>>0,b>>>0,c>>>0,e>>>0)}},Y=function(){function a(c){Y=c.exports;Y=Ja();G=Y.K;fa();M.unshift(Y.L);
N--;0==N&&(null!==O&&(clearInterval(O),O=null),P&&(c=P,P=null,c()));return Y}var b={a:Ia};N++;if(f.instantiateWasm)try{return f.instantiateWasm(b,a)}catch(c){F(`Module.instantiateWasm callback failed with error: ${c}`),l(c)}oa(b,function(c){a(c.instance)}).catch(l);return{}}();f._OrtInit=(a,b)=>(f._OrtInit=Y.M)(a,b);f._OrtGetLastError=(a,b)=>(f._OrtGetLastError=Y.N)(a,b);f._OrtCreateSessionOptions=(a,b,c,e,g,h,m,q,x,r)=>(f._OrtCreateSessionOptions=Y.O)(a,b,c,e,g,h,m,q,x,r);
f._OrtAppendExecutionProvider=(a,b)=>(f._OrtAppendExecutionProvider=Y.P)(a,b);f._OrtAddFreeDimensionOverride=(a,b,c)=>(f._OrtAddFreeDimensionOverride=Y.Q)(a,b,c);f._OrtAddSessionConfigEntry=(a,b,c)=>(f._OrtAddSessionConfigEntry=Y.R)(a,b,c);f._OrtReleaseSessionOptions=a=>(f._OrtReleaseSessionOptions=Y.S)(a);f._OrtCreateSession=(a,b,c)=>(f._OrtCreateSession=Y.T)(a,b,c);f._OrtReleaseSession=a=>(f._OrtReleaseSession=Y.U)(a);f._OrtGetInputOutputCount=(a,b,c)=>(f._OrtGetInputOutputCount=Y.V)(a,b,c);
f._OrtGetInputName=(a,b)=>(f._OrtGetInputName=Y.W)(a,b);f._OrtGetOutputName=(a,b)=>(f._OrtGetOutputName=Y.X)(a,b);f._OrtFree=a=>(f._OrtFree=Y.Y)(a);f._OrtCreateTensor=(a,b,c,e,g,h)=>(f._OrtCreateTensor=Y.Z)(a,b,c,e,g,h);f._OrtGetTensorData=(a,b,c,e,g)=>(f._OrtGetTensorData=Y._)(a,b,c,e,g);f._OrtReleaseTensor=a=>(f._OrtReleaseTensor=Y.$)(a);f._OrtCreateRunOptions=(a,b,c,e)=>(f._OrtCreateRunOptions=Y.aa)(a,b,c,e);f._OrtAddRunConfigEntry=(a,b,c)=>(f._OrtAddRunConfigEntry=Y.ba)(a,b,c);
f._OrtReleaseRunOptions=a=>(f._OrtReleaseRunOptions=Y.ca)(a);f._OrtCreateBinding=a=>(f._OrtCreateBinding=Y.da)(a);f._OrtBindInput=(a,b,c)=>(f._OrtBindInput=Y.ea)(a,b,c);f._OrtBindOutput=(a,b,c,e)=>(f._OrtBindOutput=Y.fa)(a,b,c,e);f._OrtClearBoundOutputs=a=>(f._OrtClearBoundOutputs=Y.ga)(a);f._OrtReleaseBinding=a=>(f._OrtReleaseBinding=Y.ha)(a);f._OrtRunWithBinding=(a,b,c,e,g)=>(f._OrtRunWithBinding=Y.ia)(a,b,c,e,g);f._OrtRun=(a,b,c,e,g,h,m,q)=>(f._OrtRun=Y.ja)(a,b,c,e,g,h,m,q);
f._OrtEndProfiling=a=>(f._OrtEndProfiling=Y.ka)(a);f._malloc=a=>(f._malloc=Y.la)(a);f._free=a=>(f._free=Y.ma)(a);var Ha=a=>(Ha=Y.oa)(a),Ka=a=>(Ka=Y.pa)(a),La=a=>(La=Y.qa)(a),Ma=()=>(Ma=Y.ra)();function Ja(){var a=Y;a=Object.assign({},a);var b=c=>e=>c(e)>>>0;a.la=b(a.la);a.qa=b(a.qa);a.ra=(c=>()=>c()>>>0)(a.ra);return a}f.stackSave=()=>Ma();f.stackRestore=a=>Ka(a);f.stackAlloc=a=>La(a);f.UTF8ToString=S;f.stringToUTF8=(a,b,c)=>T(a,I,b,c);f.lengthBytesUTF8=va;var Z;P=function Na(){Z||Oa();Z||(P=Na)};
function Oa(){if(!(0<N)){if(f.preRun)for("function"==typeof f.preRun&&(f.preRun=[f.preRun]);f.preRun.length;){var a=f.preRun.shift();L.unshift(a)}for(;0<L.length;)L.shift()(f);if(!(0<N||Z||(Z=!0,f.calledRun=!0,da))){for(;0<M.length;)M.shift()(f);for(k(f);0<ha.length;)ha.shift()(f)}}}Oa();


  return readyPromise
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasm);
